var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "Usage" },
    [
      _c("UsageBalance", { staticClass: "Usage-balance" }),
      _c("UsageRecharge", { staticClass: "Usage-recharge" })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }