import { __decorate, __extends } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import UsageBalance from './Usage/UsageBalance.vue';
import UsageRecharge from './Usage/UsageRecharge.vue';
var Usage = (function (_super) {
    __extends(Usage, _super);
    function Usage() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    Usage = __decorate([
        Component({
            components: { UsageBalance: UsageBalance, UsageRecharge: UsageRecharge }
        })
    ], Usage);
    return Usage;
}(Vue));
export default Usage;
