var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "UsageBalance" }, [
    _c("h1", { staticClass: "UsageBalance-title" }, [
      _vm._v("Current Balance")
    ]),
    _c("div", { staticClass: "UsageBalance-chart" }, [_c("UsageChart")], 1),
    _vm._m(0)
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "UsageBalance-footer" }, [
      _c("p", [_vm._v("Last recharge: 03-10-2020")]),
      _c("p", { staticClass: "link-blue" }, [_vm._v("Payment History")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }