import { __decorate, __extends } from "tslib";
import Overlay from '@/components/common/Overlay.vue';
import { Vue, Component, Prop } from 'vue-property-decorator';
var ConfigAutoRecharge = (function (_super) {
    __extends(ConfigAutoRecharge, _super);
    function ConfigAutoRecharge() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.autoRecharge = false;
        return _this;
    }
    ConfigAutoRecharge.prototype.closeAutoRecharge = function (_a) {
        var target = _a.target;
        if (target.classList[0] === 'Overlay')
            this.$emit('close-autoRecharge');
    };
    __decorate([
        Prop()
    ], ConfigAutoRecharge.prototype, "show", void 0);
    ConfigAutoRecharge = __decorate([
        Component({ components: { Overlay: Overlay } })
    ], ConfigAutoRecharge);
    return ConfigAutoRecharge;
}(Vue));
export default ConfigAutoRecharge;
