var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "credit-card" }, [
    _c(
      "div",
      {
        staticClass: "credit-card__inner",
        class: {
          "show-back": _vm.showBack
        }
      },
      [
        _c("CardFront", {
          attrs: {
            backgroundImage: "@/assets/card-1.jpg",
            symbolImage: _vm.symbolImage,
            cardNumber: _vm.cardNumber,
            expireMonth: _vm.expireMonth,
            expireYear: _vm.expireYear,
            name: _vm.name
          }
        }),
        _c("CardBack", {
          attrs: { cvv: _vm.cvv, backgroundImage: _vm.backgroundImage }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }