import { __decorate, __extends } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import DonutChart from './DonutChart.js';
var UsageChart = (function (_super) {
    __extends(UsageChart, _super);
    function UsageChart() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    UsageChart.prototype.mounted = function () {
        this.doughNut = new DonutChart('UsageChart');
        this.doughNut.draw({
            end: 75,
            start: 0,
            maxValue: 100,
            titlePosition: 'inner-top',
            titleText: 'FUNDS',
            outerCircleColor: '#FFC107',
            innerCircleColor: '#FFFFFF'
        });
    };
    UsageChart = __decorate([
        Component
    ], UsageChart);
    return UsageChart;
}(Vue));
export default UsageChart;
