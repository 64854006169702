import { __decorate, __extends } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import UsageChart from './UsageBalance/UsageChart.vue';
var UsageBalance = (function (_super) {
    __extends(UsageBalance, _super);
    function UsageBalance() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    UsageBalance = __decorate([
        Component({ components: { UsageChart: UsageChart } })
    ], UsageBalance);
    return UsageBalance;
}(Vue));
export default UsageBalance;
