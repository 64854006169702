var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Overlay",
    {
      attrs: { show: _vm.show },
      nativeOn: {
        click: function($event) {
          return _vm.closeAutoRecharge($event)
        }
      }
    },
    [
      _c("VCard", { staticClass: "AutoRecharge" }, [
        _c("h1", { staticClass: "text-h6" }, [
          _vm._v("Configure Auto Recharge")
        ]),
        _c("p", [
          _vm._v("Set automatic recharge amounts for your project balance.")
        ]),
        _c(
          "div",
          { staticClass: "AutoRecharge-toggle" },
          [
            _c("p", { staticClass: "AutoRecharge-toggle-title" }, [
              _vm._v("AUTO RECHARGE")
            ]),
            _c("VSwitch", {
              model: {
                value: _vm.autoRecharge,
                callback: function($$v) {
                  _vm.autoRecharge = $$v
                },
                expression: "autoRecharge"
              }
            })
          ],
          1
        )
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }