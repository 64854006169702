import { __decorate, __extends } from "tslib";
import Overlay from '@/components/common/Overlay.vue';
import { Vue, Component, Prop } from 'vue-property-decorator';
import CreditCardForm from './CreditCardForm.vue';
import { mixin as clickaway } from 'vue-clickaway';
var CardModal = (function (_super) {
    __extends(CardModal, _super);
    function CardModal() {
        return _super !== null && _super.apply(this, arguments) || this;
    }
    __decorate([
        Prop()
    ], CardModal.prototype, "show", void 0);
    CardModal = __decorate([
        Component({ components: { Overlay: Overlay, CreditCardForm: CreditCardForm }, mixins: [clickaway] })
    ], CardModal);
    return CardModal;
}(Vue));
export default CardModal;
