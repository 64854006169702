import { __assign, __awaiter, __decorate, __extends, __generator } from "tslib";
import { Vue, Component } from 'vue-property-decorator';
import CardModal from './UserRecharge/CardModal.vue';
import ConfigAutoRecharge from './UserRecharge/ConfigAutoRecharge.vue';
import toast from '@/helpers/toastHelpers';
var UsageRecharge = (function (_super) {
    __extends(UsageRecharge, _super);
    function UsageRecharge() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.paymentMethod = null;
        _this.radios = null;
        _this.chargeList = ['€ 20', '€ 40', '€ 60', '€ 100', '€ 200', '€ 500', '€ 1000', '€ 1500', '€ 2000'];
        _this.chargeListDefault = '€ 20';
        _this.autoRecharge = false;
        _this.autoRechargeModal = false;
        _this.tcModal = false;
        _this.loading = false;
        _this.error = '';
        _this.stripeSetup = {
            publishableKey: '',
            proPrice: ''
        };
        return _this;
    }
    UsageRecharge.prototype.mounted = function () {
        return __awaiter(this, void 0, void 0, function () {
            var _a, session_id, error, _b, data, errors;
            return __generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _a = this.$route.query, session_id = _a.session_id, error = _a.error;
                        if (!session_id)
                            return [3, 2];
                        return [4, this.$apollo.query({
                                query: require('@/graphql/stripe/retrieveCheckoutSession.gql'),
                                variables: { session_id: session_id }
                            })];
                    case 1:
                        _b = _c.sent(), data = _b.data, errors = _b.errors;
                        !errors
                            ? toast.goodMessage('', 'Succesfully processed your payment!')
                            : toast.badMessage('process your payment');
                        _c.label = 2;
                    case 2:
                        if (error)
                            toast.badMessage('process your payment');
                        return [2];
                }
            });
        });
    };
    UsageRecharge.prototype.beforeMount = function () {
        return __awaiter(this, void 0, void 0, function () {
            var data, error_1;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4, this.$apollo.query({
                                query: require('@/graphql/stripe/setup.gql')
                            })];
                    case 1:
                        data = (_a.sent()).data;
                        this.stripeSetup = __assign(__assign({}, this.stripeSetup), data.stripeSetup);
                        this.stripe = window.Stripe(this.stripeSetup.publishableKey);
                        return [3, 3];
                    case 2:
                        error_1 = _a.sent();
                        this.error = error_1.message || '';
                        return [3, 3];
                    case 3: return [2];
                }
            });
        });
    };
    UsageRecharge.prototype.createCheckoutSession = function () {
        return __awaiter(this, void 0, void 0, function () {
            var proPrice, data, error_2;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        proPrice = this.stripeSetup.proPrice;
                        _a.label = 1;
                    case 1:
                        _a.trys.push([1, 3, , 4]);
                        return [4, this.$apollo.query({
                                query: require('@/graphql/stripe/checkoutSession.gql'),
                                variables: { priceId: proPrice }
                            })];
                    case 2:
                        data = (_a.sent()).data;
                        console.log('createCheckoutSession -> data', data);
                        this.stripe.redirectToCheckout({ sessionId: data.stripeCheckoutSession.id });
                        return [3, 4];
                    case 3:
                        error_2 = _a.sent();
                        this.error = error_2.message || '';
                        return [3, 4];
                    case 4: return [2];
                }
            });
        });
    };
    UsageRecharge = __decorate([
        Component({ components: { CardModal: CardModal, ConfigAutoRecharge: ConfigAutoRecharge } })
    ], UsageRecharge);
    return UsageRecharge;
}(Vue));
export default UsageRecharge;
