var render = function() {
  var this$1 = this
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "Overlay",
    { attrs: { show: _vm.show } },
    [
      _c(
        "CreditCardForm",
        _vm._g(
          {
            directives: [
              {
                name: "on-clickaway",
                rawName: "v-on-clickaway",
                value: function() {
                  return this$1.$emit("close-card")
                },
                expression: "() => this.$emit('close-card')"
              }
            ]
          },
          _vm.$listeners
        )
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }