var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "card-front" }, [
    _c("img", {
      staticClass: "card-front__image",
      attrs: { src: require("@/assets/card/card-1.jpg") }
    }),
    _c("img", {
      staticClass: "card-front__symbol",
      attrs: { src: _vm.symbolImage }
    }),
    _c("img", {
      staticClass: "card-front__chip",
      attrs: { src: require("@/assets/card/chip.png") }
    }),
    _c("p", { staticClass: "card-front__number" }, [
      _vm._v(_vm._s(_vm.cardNumber))
    ]),
    _c("div", { staticClass: "card-front__info" }, [
      _c("p", [_vm._v("Expires")]),
      _c("p", { staticClass: "card-front__expires value" }, [
        _vm._v(
          " " +
            _vm._s(_vm.expireMonth || "MM") +
            " / " +
            _vm._s((_vm.expireYear && _vm.sliceYear) || "YY") +
            " "
        )
      ])
    ]),
    _c("div", { staticClass: "card-front__info left" }, [
      _c("p", [_vm._v("Card Owner")]),
      _c("p", { staticClass: "value" }, [_vm._v(_vm._s(_vm.name || "name"))])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }